<template>
  <a-modal
    :visible="visible"
    :mask-closable="false"
    :title="$t('pages_register_016')"
    :confirm-loading="loading"
    :ok-text="$t('pages_register_016')"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <a-form ref="editRef" label-align="left" :model="editForm" :rules="editRules" :label-col="{span: 5}">
      <a-form-item name="email" :label="$t('pages_register_017')">
        <a-input v-model:value="editForm.email" disabled />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 5 }">
        <div style="font-weight: 500">{{ $t('pages_user_066') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_067') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_068') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_069') }}</div>
      </a-form-item>
      <a-form-item name="pwd" :label="$t('pages_register_016')">
        <a-input-password
          v-model:value="editForm.pwd"
          :placeholder="$t('common_text_006') + $t('pages_register_016')"
        />
      </a-form-item>
      <a-form-item name="confirmPwd" :label="$t('pages_user_059')">
        <a-input-password
          v-model:value="editForm.confirmPwd"
          :placeholder="$t('common_text_006') + $t('pages_user_059')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { _login } from '@/api'
import i18n from '@/locale'
export default {
  name: 'PasswordSet',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props) {
    const validPasswordFormat = (rule, value) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
      if (!value) return Promise.reject(i18n.global.t(rule.field === 'pwd' ? 'pages_register_016' : 'pages_user_059') + i18n.global.t('common_text_042'))
      if (!pattern.test(value)) return Promise.reject(i18n.global.t('pages_user_060'))
      if (rule.field === 'confirmPwd' && value !== state.editForm.pwd) return Promise.reject(i18n.global.t('pages_user_061'))
      return Promise.resolve()
    }
    const editRef = ref(null)
    const router = useRouter()
    const state = reactive({
      loading: false,
      editForm: {
        pwd: '',
        confirmPwd: ''
      },
      editRules: {
        pwd: [{ required: true, validator: validPasswordFormat }],
        confirmPwd: [{ required: true, validator: validPasswordFormat }]
      }
    })

    const initParams = (editForm) => {
      state.editForm = Object.assign(state.editForm, editForm)
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          return _login.registerUser(state.editForm)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_register_023'))
            router.replace('/login')
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    return {
      editRef,
      initParams,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
