<template>
  <div id="register">
    <a-layout class="layout-style">
      <a-layout-header style="background: #F8F9FB">
        <a-row justify="end" align="middle">
          <GlobalOutlined class="ic-global" />
          <language-selector />
          <span class="main-text-color" style="margin-left: 20px; cursor: pointer" @click="$router.push('/login')">{{ $t('pages_register_013') }}</span>
        </a-row>
      </a-layout-header>
      <a-layout-content>
        <a-row align="middle">
          <img :src="themeData.darkLogoList" class="logo">
        </a-row>
        <a-row v-if="!isInvalid" class="register-body flex-align">
          <div class="register-img">
            <img :src="themeData.bgList">
          </div>
          <div class="card-register">
            <h1>{{ $t('pages_register_001') }}</h1>
            <div class="card-body">
              <a-form
                ref="registerRef"
                layout="vertical"
                class="register-form ant-round-form"
                :model="registerForm"
                :rules="registerRules"
                @finish="handleRegister"
              >
                <a-form-item name="email" :label="$t('pages_register_002')">
                  <a-input v-model:value="registerForm.email" :placeholder="$t('pages_register_003')" allow-clear />
                </a-form-item>

                <a-form-item name="mobile" :label="$t('pages_register_004')">
                  <div class="flex-align">
                    <a-form-item-rest>
                      <a-select v-model:value="registerForm.areaPhoneCode" style="flex: 1" show-search>
                        <a-select-option
                          v-for="item in areaCodeList"
                          :key="item"
                          :value="item"
                        >+{{ item }}</a-select-option>
                      </a-select>
                    </a-form-item-rest>
                    <a-input v-model:value="registerForm.mobile" style="flex: 2; margin-left: 24px" :placeholder="$t('pages_register_005')" allow-clear />
                  </div>
                </a-form-item>

                <a-form-item name="verificationCode" :label="$t('pages_register_006')">
                  <div class="flex-align">
                    <a-input v-model:value="registerForm.verificationCode" allow-clear :placeholder="$t('pages_register_007')" />
                    <a-button
                      type="primary"
                      shape="round"
                      style="margin-left: 24px"
                      class="send-btn"
                      :disabled="countNum > 0"
                      :loading="codeLoading"
                      @click="sendCode"
                    >{{ countNum > 0 ? `${countNum}${$t('pages_register_011')}` : $t('pages_register_008') }}</a-button>
                  </div>
                </a-form-item>

                <a-form-item v-if="!isReferrer" name="referrer" :label="$t('pages_register_009')">
                  <a-input v-model:value="registerForm.referrer" :placeholder="$t('pages_register_010')" allow-clear />
                </a-form-item>

                <a-form-item style="margin-top: 48px">
                  <a-button
                    style="width: 100%"
                    shape="round"
                    type="primary"
                    html-type="submit"
                    :loading="loading"
                    :disabled="!isAgree"
                  >{{ $t('pages_register_001') }}</a-button>
                </a-form-item>
                <a-form-item>
                  <a-checkbox v-model:checked="isAgree">
                    <span>{{ $t('pages_register_018') }}</span>
                    <template v-if="saasName !== 'OTT'">
                      <a v-for="(x, i) in agreementList" :key="i" :href="`#/agreement/${x.agreementKey}`" target="_blank">《{{ x.agreementName }}》</a>
                    </template>
                    <template v-else>
                      <a href="#/terms_service" target="_blank">{{ $t('pages_register_019') }}</a>
                      <a href="#/privacy_policy" target="_blank">{{ $t('pages_register_020') }}</a>
                    </template>
                  </a-checkbox>
                </a-form-item>
                <a-form-item style="text-align: center; margin-top: -18px;  font-size: 12px">
                  <span style="color: #999">{{ $t('pages_register_012') }}</span>
                  <span class="main-text-color" style="cursor: pointer" @click="$router.push('/login')">{{ $t('pages_register_013') }}</span>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </a-row>
        <div v-else class="link-invalid">
          <img src="~@/assets/images/link-invalid.png">
          <div>{{ $t('pages_register_015') }}</div>
        </div>
      </a-layout-content>
    </a-layout>
    <password-set ref="setRef" v-model:visible="visible" />
    <div class="keep-record">
      <div v-if="themeData.address">{{ themeData.address }}</div>
      <a target="_blank" href="https://beian.miit.gov.cn">{{ themeData.copyRight }}</a>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, inject, defineComponent, onUnmounted } from 'vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import PasswordSet from './components/password-set'
import i18n from '@/locale'
import Cookies from 'js-cookie'
import { _login, _user } from '@/api'
import LangSelector from '@/components/lang-sel'
export default defineComponent({
  name: 'Register',
  components: {
    'password-set': PasswordSet,
    'language-selector': LangSelector
  },
  setup () {
    let timer = ref(null)
    const setRef = ref(null)
    const registerRef = ref(null)
    const route = useRoute()

    const agreementList = ref([])
    const saasName = ref(process.env.VUE_APP_SAASNAME)

    const state = reactive({
      themeData: inject('$themeData'),
      isAgree: false,
      isInvalid: false,
      visible: false,
      countNum: 0,
      codeLoading: false,
      isReferrer: false,
      linkFlag: false,
      loading: false,
      areaCodeList: [],
      registerForm: {
        email: '',
        areaPhoneCode: '',
        mobile: '',
        verificationCode: '',
        referrer: ''
      },
      registerRules: {
        email: [{ required: true }],
        mobile: [
          { required: true },
          { pattern: new RegExp(/^[,\\.#%'\\+\\*\-:;^_`() 0-9A-Za-z]+$/), message: i18n.global.t('pages_register_014') }
        ],
        verificationCode: [{ required: true }],
        referrer: [{ required: true }]
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const sendCode = async () => {
      registerRef.value.validateFields('mobile')
        .then(() => {
          state.codeLoading = true
          return _login.getVerificationCode({
            mobile: state.registerForm.mobile,
            areaPhoneCode: state.registerForm.areaPhoneCode
          })
        })
        .then(res => {
          state.codeLoading = false
          if (res.data.succ) {
            state.countNum = 60
            timer = setInterval(() => {
              state.countNum--
              if (state.countNum === 0) clearInterval(timer)
            }, 1000)
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const handleRegister = async () => {
      state.loading = true
      const res = await _login.checkUserInfo(state.registerForm)
      state.loading = false
      if (res.data.succ) {
        const recommendCode = Cookies.get('recommendCode')
        // const fromRegister = Cookies.get('fromRegister')
        // if (fromRegister) state.from = fromRegister
        if (recommendCode && !state.registerForm.referrer) state.registerForm.referrer = recommendCode
        const params = Object.assign({}, state.registerForm, {
          verificationCodeUuid: res.data.data,
          linkFlag: state.linkFlag,
          from: (state.from === 'undefined' || !state.from) ? null : state.from
        })
        setRef.value.initParams(params)
        state.visible = true
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadPhoneAreaCode = async () => {
      const res = await _user.getPhoneAreaCode()
      if (res.data.succ) {
        const areaCodeList = []
        for (const item of res.data.data) {
          if (item === '86') state.registerForm.areaPhoneCode = item
          areaCodeList.push(item)
        }
        state.areaCodeList = areaCodeList
        if (!state.registerForm.areaPhoneCode) state.registerForm.areaPhoneCode = areaCodeList[0]
      }
    }

    const loadSalesReferralCode = async (recommendUrl) => {
      if (recommendUrl) {
        const res = await _login.getRegisterUser({ recommendUrl })
        if (res.data.succ) {
          const { recommendCode } = res.data.data
          const fromNo = Cookies.get('fromRegister')
          if (fromNo) state.from = fromNo
          if (recommendCode) {
            state.isReferrer = true
            state.registerForm.referrer = recommendCode
            state.linkFlag = true
          }
        } else if (res.data.resCode === '010055') {
          state.isInvalid = true
        }
      }
    }

    const getSaasMerAgreement = async () => {
      const res = await _user.getSaasMerAgreement({})
      if (res.data.succ) {
        agreementList.value = res.data.data
      }
    }

    const init = () => {
      const { key } = route.query
      loadPhoneAreaCode()
      loadSalesReferralCode(key)
      process.env.VUE_APP_SAASNAME !== 'OTT' && getSaasMerAgreement()
    }

    init()

    return {
      setRef,
      registerRef,
      saasName,
      agreementList,
      handleRegister,
      sendCode,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less" scoped>
#register {
  .scrollbar-none;
  background: #F8F9FB;
  height: 100vh;
  overflow-y: scroll;
  .ic-global {
    color: @main-color;
  };
  .logo {
    width: 180px;
  };
  .register-body {
    margin-top: 54px;
    box-shadow: 0px 2px 9px 6px rgba(245, 245, 245, 0.5);
    border-radius: 8px;
    background: #fff;
    .register-img {
      flex: 1.5;
      overflow: hidden;
      img {
        width: 484px;
        height: 440px;
      }
    }
    .card-register {
      flex: 1;
      text-align: left;
      padding: 39px 63px 0 44px;
      .card-body {
        margin: 30px 0 0 37px;
        .send-btn {
          background: @table-th-color;
          border: none;
          color: #000;
        }
      }
    }
  }
  .link-invalid {
    margin-top: 188px;
    img {
      width: 100px;
    }
  }
  .keep-record {
    text-align: center;
    color: #9c9d9c;
    padding-top: 128px;
    a {
      color: #9c9d9c;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
#resetPassword::-webkit-scrollbar {
  display: none;
}
</style>
